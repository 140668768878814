import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Lodash
import _drop from "lodash/drop";

// Components
import {
  ControlOutlined,
  DollarOutlined,
  DashboardOutlined,
  FormOutlined,
  GroupOutlined,
  Menu,
  Title,
  UserOutlined,
  NotificationOutlined,
  CompressOutlined,
  AppstoreOutlined,
  TableOutlined,
  TagOutlined,
  WechatOutlined,
  TransactionOutlined,
  InteractionOutlined,
} from "../../../../atoms";
// import PermissionControl from "../../../../molecules/permissionControl";

// Assets
import logo from "../../../../../assets/images/logo.png";

// Styles
import styles from "./sideBar.module.scss";

const { SubMenu } = Menu;

function Sidebar() {
  const [activeMenuItems, setActiveMenuItems] = useState([
    "dashboard",
    "ecommerce",
  ]);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const currentActiveMenuItem = pathname?.split("/");
    setActiveMenuItems(_drop(currentActiveMenuItem, 1));
  }, [location]);

  const { REACT_APP_URL, NODE_ENV } = process.env
  const devAll = REACT_APP_URL === "dev"
  const dev = NODE_ENV === "development"
  return (
    <>
      <div className={styles.logo}>
        {!dev ? <img className="h-8 w-auto" src={logo} alt="Dudi Admin" /> : ""}
        <Title className={styles.appTitle}>Admin</Title>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={activeMenuItems}
        className={styles.menuContainer}
        theme="dark"
        defaultOpenKeys={["dashboard"]}
      >
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <SubMenu
          key="adminmanagement"
          icon={<GroupOutlined />}
          title="Admin Management"
        >
          <Menu.Item key="admins" icon={<UserOutlined />}>
            <Link to="/adminmanagement/admins">Admins</Link>
          </Menu.Item>
          <Menu.Item key="roles" icon={<ControlOutlined />}>
            <Link to="/adminmanagement/roles">Roles</Link>
          </Menu.Item>
          <Menu.Item key="configuration" icon={<ControlOutlined />}>
            <Link to="/configuration">Configuration</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="business"
          icon={<GroupOutlined />}
          title="Business Management"
        >
          <Menu.Item key="accounts" icon={<UserOutlined />}>
            <Link to="/business/accounts">Business Accounts</Link>
          </Menu.Item>
          {/* <PermissionControl
            validFor={{
              [MODULES.VENUES.id]: [
                PERMISSIONS_TYPES.CREATE.id,
                PERMISSIONS_TYPES.VIEW.id,
              ],
            }}
          >
            <Menu.Item key="venues" icon={<EnvironmentOutlined />}>
              <Link to="/business/venues">Venues</Link>
            </Menu.Item>
          </PermissionControl> */}

          <Menu.Item key="freelancers" icon={<UserOutlined />}>
            <Link to="/business/freelancers">Freelancers</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="users" icon={<UserOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
        {dev ?
          <Menu.Item key="chats" icon={<WechatOutlined />}>
            <Link to="/chats">Chat</Link>
          </Menu.Item>
          : null}
        <Menu.Item key="sports" icon={<FormOutlined />}>
          <Link to="/sports">Interests</Link>
        </Menu.Item>
        <Menu.Item key="sales" icon={<DollarOutlined />}>
          <Link to="/sales">Sales</Link>
        </Menu.Item>
        <Menu.Item key="payments" icon={<DollarOutlined />}>
          <Link to="/payments/history">Payments</Link>
        </Menu.Item>
        {dev || devAll ?
          <Menu.Item key="transaction" icon={<TransactionOutlined />}>
            <Link to="/transaction">Transaction</Link>
          </Menu.Item>
          : null}
        <Menu.Item key="notification" icon={<NotificationOutlined />}>
          <Link to="/notification">Notification</Link>
        </Menu.Item>
        <Menu.Item key="companies" icon={<CompressOutlined />}>
          <Link to="/companies">Companies</Link>
        </Menu.Item>
        <Menu.Item key="posts" icon={<TableOutlined />}>
          <Link to="/posts">Posts</Link>
        </Menu.Item>
        <Menu.Item key="packages" icon={<AppstoreOutlined />}>
          <Link to="/packages">Packages</Link>
        </Menu.Item>
        <Menu.Item key="activities" icon={<InteractionOutlined />}>
          <Link to="/activities">Activities</Link>
        </Menu.Item>
        <Menu.Item key="promotion" icon={<TagOutlined />}>
          <Link to="/promotion">Promotion</Link>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidebar;
